import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

const RestartButton = ({ onClick }) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(
    false
  )

  const handleClose = () => {
    setIsConfirmationDialogOpen(false)
  }

  const handleConfirm = () => {
    handleClose()
    onClick()
  }

  return (
    <>
      <Button
        onClick={() => setIsConfirmationDialogOpen(true)}
        startIcon={<FontAwesomeIcon icon={faUndo} className="mr-2" />}
      >
        Restart game
      </Button>

      <Dialog
        open={isConfirmationDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Restart game?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to restart this game? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            autoFocus
          >
            Restart
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

RestartButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default RestartButton
