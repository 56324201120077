import React, { useEffect } from 'react'
import { useUser } from '../state/User/hooks'
import { useGame } from '../state/Game/hooks'
import Board from '../components/Game/Board/Board'
import RoomInfo from '../components/Game/RoomInfo/RoomInfo'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Chat from '../components/Game/Chat'
import RestartButton from '../components/Game/RestartButton'

const Game = () => {
  const { roomId } = useParams()
  const {
    state: { user },
  } = useUser()
  const {
    state: { room, game },
    actions: { selectRoomId, newGame },
  } = useGame()

  useEffect(() => {
    selectRoomId(roomId)
  }, [roomId, selectRoomId])

  const isGameLoaded = game?.board && room?.id === roomId
  const isOwner = user && room?.ownerId === user.id

  return (
    <>
      <div className="flex justify-center items-center xl:items-stretch flex-col xl:flex-row">
        {isGameLoaded ? (
          <>
            <div
              className="px-3 order-2 xl:order-1 mt-3 xl:mt-0"
              style={{ width: 250, minWidth: 250, maxWidth: 250 }}
            >
              <RoomInfo room={room} user={user} />
            </div>
            <div className="order-1 xl:order-2">
              <Board
                board={game.board}
                style={{ width: game.board.length * 40 }}
              />
            </div>
            <div
              style={{ width: 250, maxWidth: 250 }}
              className="flex-grow flex flex-col px-3 order-3 mt-3 xl:mt-0"
            >
              <div className="flex-grow flex">
                <Chat />
              </div>
            </div>
          </>
        ) : (
          <div
            className="text-center flex items-center"
            style={{ height: 700 }}
          >
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
          </div>
        )}
      </div>

      {isOwner && (
        <div className="text-center m-4">
          <RestartButton onClick={newGame} />
        </div>
      )}
    </>
  )
}

export default Game
