export const SMALL_SIZE = 10
export const MEDIUM_SIZE = 15
export const LARGE_SIZE = 25

export default [
  {
    id: 1,
    boardSize: SMALL_SIZE,
    mineCount: 15,
  },
  {
    id: 2,
    boardSize: MEDIUM_SIZE,
    mineCount: 30,
  },
  {
    id: 3,
    boardSize: LARGE_SIZE,
    mineCount: 70,
  },
]
