import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useGame } from '../../../state/Game/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag, faSpinner } from '@fortawesome/free-solid-svg-icons'

const CellValue = ({ value, color }) => {
  switch (value) {
    case -1:
      return <FontAwesomeIcon key={1} icon={faFlag} color={color} />
    case 1:
      return <span className="font-bold text-blue-600">1</span>
    case 2:
      return <span className="font-bold text-green-700">2</span>
    case 3:
      return <span className="font-bold text-red-700">3</span>
    case 4:
      return <span className="font-bold text-blue-900">4</span>
    case 5:
      return <span className="font-bold text-red-900">5</span>
    case 6:
      return <span className="font-bold text-indigo-600">6</span>
    case 7:
      return <span className="font-bold text-black">7</span>
    case 8:
      return <span className="font-bold text-gray-600">8</span>
    default:
      return '⁣'
  }
}

CellValue.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

const Cell = ({ cell, x, y, inBombRadius }) => {
  const {
    state: { game, isOwnTurn, isPlacingBomb },
    actions: { reveal, placeBomb, setIsPlacingBomb, setHighlightedCell },
  } = useGame()

  const [waitingForReveal, setWaitingForReveal] = useState(false)

  useEffect(() => {
    setWaitingForReveal(false)
  }, [cell])

  const setWaitingIfNotRevealed = useCallback(() => {
    if (!cell.revealed) {
      setWaitingForReveal(true)
    }
  }, [cell])

  if (cell.revealed) {
    return (
      <button
        title={cell.revealedBy.name}
        className="Cell cursor-default border rounded flex justify-center items-center"
        style={{
          borderColor: cell.revealedBy.color,
          minWidth: 1,
        }}
      >
        <CellValue value={cell.value} color={cell.revealedBy.color} />
      </button>
    )
  }

  if (waitingForReveal) {
    return (
      <button
        disabled
        className="Cell cursor-default border rounded flex justify-center items-center"
      >
        <FontAwesomeIcon icon={faSpinner} color="lightgrey" spin />
      </button>
    )
  }

  const isActive = isOwnTurn && game?.started && !game?.finished

  const revealValue = () => {
    setTimeout(setWaitingIfNotRevealed, 250)

    if (isPlacingBomb) {
      setIsPlacingBomb(false)
      placeBomb(x, y)
    } else {
      reveal(x, y)
    }
  }

  const onHover = () => {
    if (isPlacingBomb) {
      setHighlightedCell({ x, y })
    }
  }

  const onExit = () => {
    if (isPlacingBomb) {
      setHighlightedCell(undefined)
    }
  }

  return (
    <button
      onClick={revealValue}
      onMouseEnter={onHover}
      onMouseLeave={onExit}
      className={`Cell border rounded border-gray-200 bg-gray-100 ${
        isActive ? 'hover:bg-white' : 'cursor-default'
      } ${inBombRadius ? 'bg-red-400' : ''}`}
      disabled={!isActive}
    >
      {'⁣'}
    </button>
  )
}

Cell.propTypes = {
  cell: PropTypes.object.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  inBombRadius: PropTypes.bool,
}

export default Cell
