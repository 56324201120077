import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Cell from './Cell'
import { useGame } from '../../../state/Game/hooks'
import './board.css'
import { LARGE_SIZE, MEDIUM_SIZE, SMALL_SIZE } from '../../../gameTypes'

const BOMB_RADIUS = 1
const FONT_SIZES = {
  [SMALL_SIZE]: 'clamp(10px, 5vw, 32px)',
  [MEDIUM_SIZE]: 'clamp(10px, 4vw, 24px)',
  [LARGE_SIZE]: 'clamp(10px, 2.5vw, 16px)',
}

const Board = ({ board }) => {
  const {
    state: { isPlacingBomb, highlightedCell },
  } = useGame()

  const fontSize = useMemo(
    () =>
      FONT_SIZES[board.length] ?? `clamp(10px, 4vw, ${300 / board.length}px)`,
    [board.length]
  )

  return (
    <div
      className="flex flex-col CellContainer"
      style={{
        width: 700,
        maxWidth: '100vw',
        fontSize,
        lineHeight: fontSize,
      }}
    >
      {board.map((row, i) => (
        <div key={i} className="CellContainer flex justify-around">
          {row.map((cell, j) => {
            const inBombRadius =
              isPlacingBomb &&
              highlightedCell &&
              Math.abs(i - highlightedCell.x) <= BOMB_RADIUS &&
              Math.abs(j - highlightedCell.y) <= BOMB_RADIUS
            return (
              <Cell
                key={i * board.length + j}
                cell={cell}
                x={i}
                y={j}
                inBombRadius={inBombRadius}
              />
            )
          })}
        </div>
      ))}
    </div>
  )
}

Board.propTypes = {
  board: PropTypes.array.isRequired,
}

export default Board
