import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { GameContext } from './context'
import PropTypes from 'prop-types'
import { useUser } from '../User/hooks'
import { Link, useHistory } from 'react-router-dom'
import { useNotification } from '../../Notification/hooks'
import { usePageVisibility } from 'react-page-visibility'
import { useSnackbar } from 'notistack'

export const GameProvider = ({ children }) => {
  const history = useHistory()
  const isVisible = usePageVisibility()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [roomId, setRoomId] = useState()
  const [room, setRoom] = useState()
  const [game, setGame] = useState()
  const [isPlacingBomb, setIsPlacingBomb] = useState(false)
  const [highlightedCell, setHighlightedCell] = useState()

  const {
    state: { connected, user, allowNotifications },
    actions: { send, subscribe, unsubscribe },
  } = useUser()

  const {
    actions: { notify },
  } = useNotification()

  const handleRoomNotFound = useCallback(() => {
    // TODO notify the user
    history.replace('/')
  }, [history])

  const handleRoomData = useCallback(
    data => {
      if (data.id !== roomId) {
        // Ignore updates for other rooms
        return
      }

      const { game, ...roomData } = data
      setRoom(roomData)
      setGame(game)
    },
    [roomId]
  )

  const handleYourTurn = useCallback(
    r => {
      if (!allowNotifications) {
        return
      }

      if (room?.id === r.id) {
        const message = `Your turn, ${user.name}!`
        if (!isVisible) {
          // Use revealed cells as a tag to avoid repeating notifications for the same board
          notify('Minesweeper', message)
        }
        enqueueSnackbar(message)
      } else {
        const snackbarKey = Math.random().toString()
        const otherUsersNames = r.users
          .filter(u => u.id !== user.id)
          .map(u => u.name)
        const userNames =
          otherUsersNames.length === 1
            ? otherUsersNames[0]
            : otherUsersNames.slice(0, -1).join(', ') +
              ' and ' +
              otherUsersNames.slice(-1)

        enqueueSnackbar(
          <Link to={'/' + r.id} onClick={() => closeSnackbar(snackbarKey)}>
            It&apos;s your turn in your game with {userNames}
          </Link>,
          { key: snackbarKey }
        )
      }
    },
    [
      room,
      user,
      closeSnackbar,
      enqueueSnackbar,
      isVisible,
      notify,
      allowNotifications,
    ]
  )

  useEffect(() => {
    if (connected) {
      subscribe('room_not_found', handleRoomNotFound)
      subscribe('room', handleRoomData)
      subscribe('your_turn', handleYourTurn)
    }

    return () => {
      unsubscribe('room_not_found', handleRoomNotFound)
      unsubscribe('room', handleRoomData)
      unsubscribe('your_turn', handleYourTurn)
    }
  }, [
    connected,
    handleRoomData,
    handleYourTurn,
    handleRoomNotFound,
    subscribe,
    unsubscribe,
  ])

  const join = useCallback(
    roomId => {
      send('join', roomId)
    },
    [send]
  )

  useEffect(() => {
    if (user && roomId) {
      join(roomId)
    }
  }, [roomId, user, join])

  const isOwnTurn = useMemo(
    () =>
      user &&
      room &&
      game &&
      game.started &&
      !game.finished &&
      room.users[room.currentIndex]?.id === user.id,
    [user, room, game]
  )

  const selectRoomId = roomId => {
    setRoomId(roomId)
  }

  const goIdle = () => {
    setRoomId(undefined)
    setRoom(undefined)
  }

  const startGame = () => {
    send('start_game')
  }

  const newGame = () => {
    send('new_game')
  }

  /**
   * @param {number} x
   * @param {number} y
   */
  const reveal = (x, y) => {
    send('reveal', { x, y })
  }

  /**
   * @param {number} x
   * @param {number} y
   */
  const placeBomb = (x, y) => {
    send('bomb', { x, y })
  }

  const sendChatMessage = msg => {
    send('chat_message', msg)
  }

  const value = {
    state: { room, game, isOwnTurn, isPlacingBomb, highlightedCell },
    actions: {
      selectRoomId,
      startGame,
      newGame,
      goIdle,
      reveal,
      placeBomb,
      sendChatMessage,
      setIsPlacingBomb,
      setHighlightedCell,
    },
  }

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>
}

GameProvider.propTypes = {
  children: PropTypes.node,
}
