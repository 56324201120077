import React, { useRef } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { NotificationProvider } from './Notification/provider'
import { SnackbarProvider } from 'notistack'
import { UserProvider } from './state/User/provider'
import { GameProvider } from './state/Game/provider'
import Routes from './Routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const App = () => {
  const notistackRef = useRef()

  const onDismissNotification = key => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <Router>
      <NotificationProvider>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          action={key => (
            <button onClick={() => onDismissNotification(key)}>
              <FontAwesomeIcon
                icon={faTimes}
                title="Close notification"
                className="mr-2"
              />
            </button>
          )}
        >
          <UserProvider>
            <GameProvider>
              <Routes />
            </GameProvider>
          </UserProvider>
        </SnackbarProvider>
      </NotificationProvider>
    </Router>
  )
}

export default App
