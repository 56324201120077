import React from 'react'
import Header from './components/Header/Header'
import { Route, Switch } from 'react-router-dom'
import Game from './screens/Game'
import Home from './screens/Home'

const Routes = () => (
  <>
    <Header />
    <Switch>
      <Route path="/:roomId">
        <Game />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  </>
)

export default Routes
