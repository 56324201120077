import React, { useMemo } from 'react'
import BoardPreview from './BoardPreview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import getMinesRemainingText from '../../utils/getMinesRemainingText'
import PropTypes from 'prop-types'
import { ButtonBase } from '@material-ui/core'
import { Link } from 'react-router-dom'

const RoomListItem = ({ user, room }) => {
  const isOwnTurn = useMemo(
    () =>
      room.game.started &&
      !room.game.finished &&
      room.users[room.currentIndex].id === user.id,
    [user, room]
  )
  const usersList = useMemo(
    () =>
      room.users
        .slice()
        .sort((a, b) => (room.scores[b.id] ?? 0) - (room.scores[a.id] ?? 0))
        .map(u =>
          room.scores[u.id] ? `${u.name} ${room.scores[u.id]}` : u.name
        )
        .join(', '),
    [room]
  )
  const minesRemainingText = useMemo(
    () => getMinesRemainingText(room, room.game),
    [room]
  )

  return (
    <ButtonBase
      focusRipple
      focusVisibleClassName=""
      component={Link}
      to={'/' + room.id}
      style={{ width: 160 }}
    >
      <div className="flex flex-col gap-2 p-2 pt-4 justify-center items-center rounded hover:bg-gray-100 active:bg-gray-300">
        <BoardPreview board={room.game.board} />
        <span className="font-bold text-sm">
          {isOwnTurn && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              title="Your turn!"
              color="tomato"
              className="mr-1"
            />
          )}
          {usersList}
          {!room.game.finished && room.game.started && (
            <span> ({minesRemainingText})</span>
          )}
        </span>
      </div>
    </ButtonBase>
  )
}

RoomListItem.propTypes = {
  user: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
}

export default RoomListItem
