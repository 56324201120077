import React from 'react'
import PropTypes from 'prop-types'

const PREVIEW_SIZE = 100
const MINE = -1

const BoardPreview = ({ board }) => {
  const boardSize = board.length
  const cellSize = PREVIEW_SIZE / boardSize
  return (
    <div
      style={{
        width: PREVIEW_SIZE + 2,
        height: PREVIEW_SIZE + 2,
      }}
      className="border rounded overflow-hidden border-gray-600"
    >
      {board.map((row, index) => (
        <div key={index} className="flex">
          {row.map((cell, index) => {
            let color = '#f3f4f6'
            if (cell.revealed) {
              if (cell.value !== MINE) {
                color = 'white'
              } else {
                color = cell.revealedBy.color
              }
            }
            return (
              <div
                key={index}
                style={{
                  width: cellSize,
                  height: cellSize,
                  backgroundColor: color,
                }}
              />
            )
          })}
        </div>
      ))}
    </div>
  )
}

BoardPreview.propTypes = {
  board: PropTypes.array.isRequired,
}

export default BoardPreview
