import React, { useState } from 'react'
import { NotificationContext } from './context'
import PropTypes from 'prop-types'
import Notification from 'react-web-notification'

export const NotificationProvider = ({ children }) => {
  const [ignoreNotifications, setIgnoreNotifications] = useState(true)
  const [notification, setNotification] = useState({ title: '', body: '' })

  const handlePermissionGranted = () => {
    setIgnoreNotifications(false)
  }

  const handlePermissionDenied = () => {
    setIgnoreNotifications(true)
  }
  const handleNotSupported = () => {
    setIgnoreNotifications(true)
  }

  const notify = (title, body, tag) => {
    setNotification({ title, body, tag })
  }

  const value = {
    actions: {
      notify,
    },
  }

  return (
    <NotificationContext.Provider value={value}>
      <Notification
        ignore={ignoreNotifications && notification.title !== ''}
        notSupported={handleNotSupported}
        onPermissionGranted={handlePermissionGranted}
        onPermissionDenied={handlePermissionDenied}
        timeout={5000}
        title={notification.title}
        options={{
          body: notification.body,
          tag: notification.tag,
          lang: 'en',
        }}
      />
      {children}
    </NotificationContext.Provider>
  )
}

NotificationProvider.propTypes = {
  children: PropTypes.node,
}
