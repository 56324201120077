import React, { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBomb,
  faChevronRight,
  faPlay,
} from '@fortawesome/free-solid-svg-icons'
import { useUser } from '../../../state/User/hooks'
import { useGame } from '../../../state/Game/hooks'
import TurnInfo from './TurnInfo'
import getMinesRemainingText from '../../../utils/getMinesRemainingText'
import { Button, IconButton, Tooltip } from '@material-ui/core'

const RoomInfo = () => {
  const {
    state: { user },
  } = useUser()
  const {
    state: { room, game, isOwnTurn, isPlacingBomb },
    actions: { startGame, setIsPlacingBomb },
  } = useGame()

  const [playingUsers, setPlayingUsers] = useState([])
  const [waitingUsers, setWaitingUsers] = useState([])

  useEffect(() => {
    if (!room) {
      return
    }
    const playing = []
    const waiting = []
    room.users.forEach((u, index) => {
      const userWithIndex = { ...u, index }
      room.scores[u.id] !== undefined
        ? playing.push(userWithIndex)
        : waiting.push(userWithIndex)
    })

    if (game.finished) {
      playing.sort((a, b) => room.scores[b.id] - room.scores[a.id])
    }

    setPlayingUsers(playing)
    setWaitingUsers(waiting)
  }, [room, game])

  const togglePlacingBomb = useCallback(() => {
    setIsPlacingBomb(!isPlacingBomb)
  }, [isPlacingBomb, setIsPlacingBomb])

  if (!user || !room || !game) {
    return null
  }

  const isOwner = room.ownerId === user?.id

  return (
    <div className="grid grid-cols-1 divide-y" style={{ width: '100%' }}>
      <TurnInfo />
      {isOwner && !game.started && (
        <div className="mb-2 pt-2">
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={startGame}
            startIcon={<FontAwesomeIcon icon={faPlay} />}
          >
            Start
          </Button>
        </div>
      )}

      {room && game.started && !game.finished && (
        <div className="text-lg">{getMinesRemainingText(room, game)}</div>
      )}

      <ul className="pt-3">
        {playingUsers.map(u => {
          const canPlaceBomb = room.bombs[u.id] > 0
          return (
            <li
              key={u.id}
              style={{
                color: u.color,
                fontWeight: user && u.id === user.id ? 'bold' : 'normal',
              }}
              className="flex justify-between items-center gap-3"
            >
              <span>
                {game.started &&
                  !game.finished &&
                  room.currentIndex === u.index && (
                    <FontAwesomeIcon icon={faChevronRight} className="mr-1" />
                  )}
                {u.name}
              </span>

              {game.started && (
                <span className="flex flex-end gap-2">
                  <span>{room.scores[u.id]}</span>

                  {u.id === user.id ? (
                    <Tooltip
                      title={canPlaceBomb ? 'Bomb available' : 'Bomb spent'}
                    >
                      <IconButton
                        onClick={togglePlacingBomb}
                        disabled={!isOwnTurn || !canPlaceBomb}
                        size="small"
                        aria-label="toggle bomb"
                      >
                        <FontAwesomeIcon
                          icon={faBomb}
                          color={
                            canPlaceBomb
                              ? isPlacingBomb
                                ? 'tomato'
                                : 'black'
                              : 'lightgrey'
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <span className="w-6 flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={faBomb}
                        color={canPlaceBomb ? 'black' : 'lightgrey'}
                        title={canPlaceBomb ? 'Bomb available' : 'Bomb spent'}
                      />
                    </span>
                  )}
                </span>
              )}
            </li>
          )
        })}
      </ul>

      {waitingUsers.length > 0 && (
        <>
          <h2 className="font-bold pt-10 text-gray-500">Joining next game:</h2>
          <ul className="pt-3">
            {waitingUsers.map(u => (
              <li
                key={u.id}
                style={{
                  color: u.color,
                  fontWeight: user && u.id === user.id ? 'bold' : 'normal',
                }}
                className="italic"
              >
                {u.name}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

export default RoomInfo
