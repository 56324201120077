import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useUser } from '../state/User/hooks'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSpinner, faTh } from '@fortawesome/free-solid-svg-icons'
import { useGame } from '../state/Game/hooks'
import RoomListItem from '../components/Home/RoomListItem'
import { SpeedDial, SpeedDialAction } from '@material-ui/lab'
import { Backdrop } from '@material-ui/core'
import gameTypes from '../gameTypes'

const Home = () => {
  const history = useHistory()

  const {
    state: { connected, user },
    actions: { send, subscribe, unsubscribe },
  } = useUser()
  const {
    actions: { goIdle },
  } = useGame()

  const [rooms, setRooms] = useState(
    /** @type {Array<Object>} */
    undefined
  )
  const [createButtonsOpen, setCreateButtonsOpen] = useState(false)

  useEffect(() => {
    goIdle()
  }, [goIdle])

  useEffect(() => {
    if (user) {
      send('get_room_list')
    }
  }, [user, send])

  const ongoingRooms = useMemo(
    () =>
      rooms
        ? rooms.filter(room => room.game.started && !room.game.finished)
        : [],
    [rooms]
  )
  const waitingRooms = useMemo(
    () => (rooms ? rooms.filter(room => !room.game.started) : []),
    [rooms]
  )
  const finishedRooms = useMemo(
    () => (rooms ? rooms.filter(room => room.game.finished) : []),
    [rooms]
  )

  const handleRoomCreated = useCallback(
    ({ id }) => {
      history.push('/' + id)
    },
    [history]
  )

  const handleRoomData = useCallback(
    room => {
      const index = rooms.findIndex(r => r.id === room.id)
      setRooms([...rooms.slice(0, index), room, ...rooms.slice(index + 1)])
    },
    [rooms]
  )

  useEffect(() => {
    if (connected) {
      subscribe('room_list', setRooms)
      subscribe('room_created', handleRoomCreated)
      subscribe('room', handleRoomData)
    }

    return () => {
      unsubscribe('room_list', setRooms)
      unsubscribe('room_created', handleRoomCreated)
      unsubscribe('room', handleRoomData)
    }
  }, [connected, subscribe, unsubscribe, handleRoomCreated, handleRoomData])

  const createRoom = gameType => {
    send('create_room', gameType.boardSize, gameType.mineCount)
    setCreateButtonsOpen(false)
  }

  if (!user) {
    return (
      <div
        className="mx-auto flex flex-col items-stretch justify-center"
        style={{ maxWidth: 700, minHeight: 700 }}
      >
        <FontAwesomeIcon icon={faSpinner} spin size="2x" className="mx-auto" />
      </div>
    )
  }

  const newGameFabs = (
    <div
      className="sticky bottom-0 mx-auto"
      style={{ width: '100%', maxWidth: 700 }}
    >
      <Backdrop open={createButtonsOpen} />
      <SpeedDial
        ariaLabel="create new game"
        icon={<FontAwesomeIcon icon={faPlus} />}
        open={createButtonsOpen}
        onOpen={() => setCreateButtonsOpen(true)}
        onClose={() => setCreateButtonsOpen(false)}
        direction="up"
        className="absolute bottom-5 right-5"
      >
        {gameTypes.map(gameType => {
          const title = `${gameType.boardSize}x${gameType.boardSize} (${gameType.mineCount} mines)`
          return (
            <SpeedDialAction
              key={gameType.id}
              title={title}
              icon={
                <div>
                  <span className="absolute bottom-0.5 right-1.5 font-bold font-black">
                    {gameType.mineCount}
                  </span>
                  <FontAwesomeIcon icon={faTh} color="lightgrey" size="lg" />
                </div>
              }
              tooltipTitle={<span className="whitespace-nowrap">{title}</span>}
              tooltipOpen
              onClick={() => createRoom(gameType)}
            />
          )
        })}
      </SpeedDial>
    </div>
  )

  return (
    <>
      <div
        className={`text-center mx-auto divide-y divide-gray-400 flex flex-col gap-5 items-stretch ${
          !rooms || rooms.length === 0 ? 'justify-center' : ''
        }`}
        style={{ maxWidth: 700, minHeight: 700 }}
      >
        {rooms ? (
          rooms.length === 0 && (
            <div className="flex flex-col gap-5">
              <em>No games found</em>
            </div>
          )
        ) : (
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="2x"
            className="mx-auto"
          />
        )}

        {rooms && rooms.length > 0 && (
          <div>
            <h2 className="text-xl my-3">Current games</h2>
            {ongoingRooms.length > 0 ? (
              <>
                <ul className="mb-3 flex flex-wrap justify-center gap-3">
                  {ongoingRooms.map(room => (
                    <li key={room.id}>
                      <RoomListItem user={user} room={room} />
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <div className="flex flex-col gap-5">
                <em>No games found</em>
              </div>
            )}
          </div>
        )}

        {waitingRooms.length > 0 && (
          <div>
            <h2 className="text-xl my-3">Games waiting to start</h2>
            <ul className="mb-3 flex flex-wrap justify-center gap-3">
              {waitingRooms.map(room => (
                <li key={room.id} className="font-bold text-sm">
                  <RoomListItem user={user} room={room} />
                </li>
              ))}
            </ul>
          </div>
        )}

        {finishedRooms.length > 0 && (
          <div>
            <h2 className="text-xl my-3">Finished games</h2>
            <ul className="mb-3 flex flex-wrap justify-center gap-3">
              {finishedRooms.map(room => (
                <li key={room.id} className="font-bold text-sm">
                  <RoomListItem user={user} room={room} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {newGameFabs}
    </>
  )
}

export default Home
