import React from 'react'
import { useUser } from '../../../state/User/hooks'
import { useGame } from '../../../state/Game/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const TurnInfo = () => {
  const {
    state: { user },
  } = useUser()
  const {
    state: { room, game, isOwnTurn },
  } = useGame()

  if (!game?.started) {
    return (
      <h2 className="text-lg font-bold">
        <span className="text-gray-500">Waiting for players...</span>
      </h2>
    )
  }

  if (game?.finished) {
    const sortedScores = Object.values(room.scores).sort().reverse()
    const isDraw =
      sortedScores.length > 1 && sortedScores[0] === sortedScores[1]
    if (isDraw) {
      return <h2 className="text-lg font-bold">Draw!</h2>
    }

    const winner = room.users.reduce((prev, current) =>
      (room.scores[prev.id] ?? 0) > (room.scores[current.id] ?? 0)
        ? prev
        : current
    )

    return (
      <h2 className="text-lg font-bold">
        <span style={{ color: winner.color }}>
          {winner.id === user.id ? 'You' : winner.name}
        </span>{' '}
        won!
      </h2>
    )
  }

  return isOwnTurn ? (
    <h2 className="text-lg font-bold text-green-700">
      <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
      Your turn
    </h2>
  ) : (
    <h2 className="text-lg font-bold text-gray-500">
      {room.users[room.currentIndex]?.name}
      &apos;s turn
    </h2>
  )
}

export default TurnInfo
