import React, { useEffect, useRef, useState } from 'react'
import { useUser } from '../../state/User/hooks'
import { useGame } from '../../state/Game/hooks'
import { IconButton, Tooltip } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'

const Chat = () => {
  const {
    state: { user },
  } = useUser()
  const {
    state: { room },
    actions: { sendChatMessage },
  } = useGame()

  const [message, setMessage] = useState('')
  const [lastMessage, setLastMessage] = useState()

  const chatBox = useRef()

  useEffect(() => {
    if (!room || room.chat.length === 0) {
      return
    }
    setLastMessage(room.chat[room.chat.length - 1])
  }, [room])

  useEffect(() => {
    chatBox.current.scrollTop = chatBox.current.scrollHeight
  }, [lastMessage])

  if (!user || !room) {
    return null
  }

  const send = () => {
    sendChatMessage(message)
    setMessage('')
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      send()
    }
  }

  const date = new Date(new Date().toUTCString().substr(0, 25))
  const today = date.toDateString()

  return (
    <div className="flex-grow flex flex-col" style={{ width: '100%' }}>
      <h2 className="text-lg font-bold">Chat</h2>
      <div className="border border-gray-200 rounded">
        <div
          ref={chatBox}
          className="flex-grow px-2 py-1 overflow-y-scroll"
          style={{ height: 620 }}
        >
          {room.chat.map((msg, index) => {
            const sentAtDate = new Date(msg.sentAt)
            const sentAt =
              sentAtDate.toDateString() === today
                ? sentAtDate.toLocaleTimeString()
                : sentAtDate.toLocaleString()
            return (
              <p key={index} title={sentAt}>
                <span
                  title={msg.from.name}
                  className="font-bold mr-2 truncate inline-block align-top"
                  style={{ color: msg.from.color, maxWidth: '5rem' }}
                >
                  {msg.from.name}
                </span>
                {msg.text}
              </p>
            )
          })}
        </div>
        <div className="flex justify-between items-stretch border-t border-gray-200">
          <input
            type="text"
            value={message}
            onChange={e => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            className="outline-none px-2 py-1 flex-grow"
            style={{ minWidth: 100 }}
          />
          <Tooltip title="Send">
            <IconButton onClick={send} aria-label="send">
              <SendIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default Chat
