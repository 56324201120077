import React, { useCallback, useEffect, useState } from 'react'
import { UserContext } from './context'
import { io } from 'socket.io-client'
import PropTypes from 'prop-types'

const USER_ID_KEY = 'user_id'
const ALLOW_NOTIFICATIONS_KEY = 'allow_notifications'
const NO_LAST_USER_ID = -1

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [socket, setSocket] = useState(
    /** @type import("socket.io-client").Socket | undefined */
    undefined
  )
  const [connected, setConnected] = useState(false)
  const [lastUserId, setLastUserId] = useState()
  const [allowNotifications, setAllowNotifications] = useState(true)

  useEffect(() => {
    const userId = localStorage.getItem(USER_ID_KEY)
    setLastUserId(userId || NO_LAST_USER_ID)

    const allowNotifications = localStorage.getItem(ALLOW_NOTIFICATIONS_KEY)
    setAllowNotifications(
      allowNotifications === null ? true : allowNotifications === 'true'
    )
  }, [])

  useEffect(() => {
    const socket =
      process.env.NODE_ENV === 'production' ? io() : io('http://localhost:8000')

    socket.on('connect', () => {
      console.log('Connected')
      setConnected(true)
    })

    socket.io.on('reconnect', () => {
      console.log('Reconnected')
      setConnected(true)
    })

    socket.on('disconnect', () => {
      setUser(undefined)
      setConnected(false)

      setTimeout(() => {
        console.log('Reconnecting after disconnect...')
        socket.connect()
      }, 1000)
    })

    socket.on('connect_error', reason => {
      console.log('Connection error', reason)

      setTimeout(() => {
        console.log('Reconnecting after connect error...')
        socket.connect()
      }, 1000)
    })

    socket.on('logged_in', user => {
      setUser(user)
      setLastUserId(user.id)
    })

    setSocket(socket)
  }, [])

  const send = useCallback(
    (msg, ...args) => {
      if (connected) {
        socket.emit(msg, args)
      }
    },
    [connected, socket]
  )

  useEffect(() => {
    if (!user && connected && lastUserId) {
      if (lastUserId !== NO_LAST_USER_ID) {
        send('log_in', lastUserId)
      } else {
        send('log_in')
      }
    }
  }, [user, connected, lastUserId, send])

  useEffect(() => {
    localStorage.setItem(USER_ID_KEY, lastUserId)
  }, [lastUserId])

  useEffect(() => {
    if (allowNotifications !== undefined) {
      localStorage.setItem(
        ALLOW_NOTIFICATIONS_KEY,
        allowNotifications.toString()
      )
    }
  }, [allowNotifications])

  /**
   * @param {string} msg
   * @param {function(...any): void} listener
   */
  const subscribe = (msg, listener) => {
    socket.on(msg, listener)
  }

  /**
   * @param {string} msg
   * @param {function(...any): void} listener
   */
  const unsubscribe = (msg, listener) => {
    if (socket) {
      socket.off(msg, listener)
    }
  }

  const editProfile = user => {
    send('edit_profile', user)
  }

  const value = {
    state: { user, connected, allowNotifications },
    actions: {
      send,
      subscribe,
      unsubscribe,
      editProfile,
      setAllowNotifications,
    },
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

UserProvider.propTypes = {
  children: PropTypes.node,
}
