import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from '@material-ui/core'
import { CirclePicker } from 'react-color'
import { useUser } from '../../state/User/hooks'

const ProfileEditor = ({ user, open, onSave, onClose }) => {
  const {
    state: { allowNotifications },
    actions: { setAllowNotifications },
  } = useUser()

  const [name, setName] = useState(user.name)
  const [color, setColor] = useState(user.color)
  const [notificationsAllowed, setNotificationsAllowed] = useState(
    allowNotifications
  )

  const handleSubmit = event => {
    event.preventDefault()
    onSave({ ...user, name, color })
    setAllowNotifications(notificationsAllowed)
  }

  const handleCancel = () => {
    setName(user.name)
    setColor(user.color)
    onClose()
  }

  const handleColorChange = color => {
    setColor(color.hex)
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      scroll="body"
      aria-labelledby="profile-editor-title"
    >
      <DialogTitle id="profile-editor-title">Profile</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent
          className="flex flex-col gap-5"
          style={{ overflowY: 'clip' }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <div>
            <span className="text-xs text-gray-600">Color</span>
            <div className="flex items-center">
              <CirclePicker
                color={color}
                onChangeComplete={handleColorChange}
              />
            </div>
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={notificationsAllowed}
                onChange={e => setNotificationsAllowed(e.target.checked)}
                color="primary"
              />
            }
            label="Notifications"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ProfileEditor.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ProfileEditor
