import React, { useState } from 'react'
import { useUser } from '../../state/User/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifi, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import ProfileEditor from './ProfileEditor'

const Header = () => {
  const {
    state: { user, connected },
    actions: { editProfile },
  } = useUser()
  const [isEditorOpen, setIsEditorOpen] = useState(false)

  const handleUserEdit = user => {
    editProfile(user)
    setIsEditorOpen(false)
  }

  return (
    <div style={{ maxWidth: 700, lineHeight: '24px' }} className="mx-auto">
      <div className="flex justify-between items-center flex-wrap gap-3 px-4 py-2">
        <h1 className="text-xl font-bold">
          <Link to="/">
            <div className="flex items-center gap-3">
              <img src="logo64.png" alt="Logo" height={24} width={24} />
              Minesweeper
            </div>
          </Link>
        </h1>

        {connected ? (
          <div>
            {user && (
              <>
                <Button onClick={() => setIsEditorOpen(true)}>
                  <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
                  <span
                    style={{ color: user.color, textTransform: 'none' }}
                    className="font-bold"
                  >
                    {user.name}
                  </span>
                </Button>
                <ProfileEditor
                  user={user}
                  open={isEditorOpen}
                  onSave={handleUserEdit}
                  onClose={() => setIsEditorOpen(false)}
                />
              </>
            )}
          </div>
        ) : (
          <span>
            <FontAwesomeIcon icon={faWifi} className="mr-1" />
            Connecting...
          </span>
        )}
      </div>
    </div>
  )
}

export default Header
