/**
 * @param room
 * @param game
 * @return {string}
 */
const getMinesRemainingText = (room, game) => {
  const value =
    game.mineCount -
    room.users
      .map(u => room.scores[u.id])
      .reduce((prev, curr) => (prev ?? 0) + (curr ?? 0))

  return value === 1 ? '1 mine remaining' : `${value} mines remaining`
}

export default getMinesRemainingText
